<template>
  <div v-if="!isLoadingPage">
    <div v-if="permission !== null">
      <div v-if="permission.read_perm == 1">
        <v-container
          style="
            padding: 0 0 20px 0;
            margin: auto;
            border-radius: 5px;
            background: white;
          "
        >
          <v-card
            tile
            flat
            style="
              border-bottom: 2px solid rgba(0, 0, 0, 0.1);
              margin-bottom: 20px;
              display: flex;
              justify-content: space-between;
              position: sticky;
              top: 60px;
              background: white;
              z-index: 5;
            "
          >
            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 10px;
              "
            >
              <v-toolbar-title class="overline">
                <p
                  :style="`font-size:${
                    wWidth > 780 ? '20px' : '3vw'
                  }; margin: 0;`"
                >
                  DETAIL TAMBAHAN KOMPONEN PAYROLL<v-tooltip
                    bottom
                    color="grey"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        x-small
                        text
                        icon
                        color="grey darken-2"
                        @click="getDetail"
                      >
                        <v-icon small>mdi-refresh</v-icon>
                      </v-btn>
                    </template>
                    <span>refresh page</span>
                  </v-tooltip>
                </p>

                <div
                  v-if="permission.update_perm == 1"
                  class="d-flex"
                  style="position: absolute; right: 0; top: 55px"
                >
                  <v-btn
                    v-if="!isEdit"
                    @click.prevent="editHeader()"
                    type="button"
                    text
                    elevation="0"
                    color="primary"
                    class="white--text font-weight-bold"
                    style="font-size: 12px"
                    :loading="loading"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                    Edit Header
                  </v-btn>

                  <v-btn
                    v-if="isEdit"
                    @click="submitHeader"
                    type="button"
                    text
                    outlined
                    elevation="0"
                    color="success"
                    class="white--text font-weight-bold"
                    style="font-size: 12px"
                    :loading="loading"
                  >
                    <v-icon small>mdi-save</v-icon>
                    Simpan
                  </v-btn>
                  <v-btn
                    v-if="isEdit"
                    @click="cancelHeader"
                    text
                    outlined
                    type="button"
                    elevation="0"
                    color="error"
                    class="white--text font-weight-bold"
                    style="font-size: 12px; margin-left: 10px"
                    :loading="loading"
                  >
                    Batal
                  </v-btn>
                </div>
              </v-toolbar-title>
            </div>
            <div>
              <v-btn
                type="button"
                rounded
                outlined
                elevation="0"
                color="black"
                class="black--text font-weight-bold ma-2"
                style="font-size: 12px"
                @click="close"
              >
                <v-icon small>mdi-chevron-left</v-icon>
                Kembali
              </v-btn>
            </div>
          </v-card>

          <v-card elevation="0">
            <v-form
              v-if="detail !== null"
              ref="entryForm"
              @submit.prevent="submitDetail()"
              lazy-validation
              :disabled="loading"
            >
              <v-row style="padding: 15px" no-gutters>
                <v-col cols="12" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Judul Komponen Tambahan
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-text-field
                        v-model="detail.name"
                        :disabled="!isEdit"
                        dense
                        outlined
                      />
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="3" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Komponen Payroll
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-autocomplete
                        v-model="detail.payroll_component"
                        :items="[
                          {
                            id: 10,
                            name: 'THR'
                          },
                          {
                            id: 11,
                            name: 'Bonus'
                          },
                          {
                            id: 12,
                            name: 'MCU'
                          },
                          {
                            id: 13,
                            name: 'Asuransi'
                          }
                        ]"
                        :disabled="!isEdit"
                        item-text="name"
                        item-value="id"
                        return-object
                        outlined
                        dense
                        style="margin: 0"
                      ></v-autocomplete>
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="5" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Perusahaan
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-text-field
                        :value="detail.company_name"
                        dense
                        outlined
                        disabled
                      />
                    </v-col>
                  </div>
                </v-col>
                <v-col
                  v-if="detail.department_name !== null"
                  cols="4"
                  style="padding: 0"
                >
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Department
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-text-field
                        :value="detail.department_name"
                        dense
                        outlined
                        disabled
                      />
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Template
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-text-field
                        v-if="!isEdit"
                        :value="
                          detail.template !== null ? detail.template.name : '-'
                        "
                        dense
                        outlined
                        disabled
                      />
                      <v-autocomplete
                        v-else
                        v-model="detail.template"
                        :items="dropdown.payroll_template"
                        item-text="name"
                        item-value="id"
                        return-object
                        outlined
                        dense
                        style="margin: 0"
                      ></v-autocomplete>
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Periode
                      </p>
                    </v-col>
                    <v-row no-gutters>
                      <v-col cols="3" style="padding: 0">
                        <v-text-field
                          v-if="!isEdit"
                          :value="
                            detail.period_start_year +
                            '-' +
                            ((detail.period_start_month + '').length > 1
                              ? detail.period_start_month
                              : '0' + detail.period_start_month)
                          "
                          type="month"
                          name="monthperiod"
                          step="1"
                          outlined
                          dense
                          disabled
                        ></v-text-field>
                        <div v-else>
                          <v-text-field
                            v-if="form !== null"
                            v-model="form.period_start"
                            type="month"
                            name="monthperiod"
                            step="1"
                            outlined
                            dense
                          ></v-text-field>
                        </div>
                      </v-col>

                      <v-col
                        v-if="detail.payroll_component.id === 13"
                        class="d-flex justify-center align-center"
                        cols="1"
                        style="padding: 0"
                      >
                        <p style="padding: 0; margin-bottom: 30px">s/d</p>
                      </v-col>

                      <v-col
                        v-if="detail.payroll_component.id === 13"
                        cols="3"
                        style="padding: 0 7px 0 0"
                      >
                        <v-text-field
                          v-if="!isEdit"
                          :value="
                            detail.period_end_year +
                            '-' +
                            ((detail.period_end_month + '').length > 1
                              ? detail.period_end_month
                              : '0' + detail.period_end_month)
                          "
                          type="month"
                          name="monthperiod"
                          step="1"
                          outlined
                          dense
                          disabled
                        ></v-text-field>
                        <div v-else>
                          <v-text-field
                            v-if="form !== null"
                            v-model="form.period_end"
                            type="month"
                            name="monthperiod"
                            step="1"
                            outlined
                            dense
                          ></v-text-field>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" style="padding: 0 10px; margin-top: 20px">
                  <v-card>
                    <v-data-table
                      :headers="detailHeaders"
                      :search="searchName"
                      :items="detail.additional_detail"
                      :options.sync="footer.options"
                      @update:page="updatePage"
                      @update:items-per-page="updateItemPerPage"
                      @click:row="editDetailItem"
                      :items-per-page="10"
                      :footer-props="{
                        showFirstLastPage: true,
                        showCurrentPage: true,
                        itemsPerPageOptions: [10, 15, 100, 1000]
                      }"
                      mobile-breakpoint="0"
                      style="cursor: pointer"
                    >
                      <template v-slot:top>
                        <div
                          class="d-flex justify-content-between align-center"
                        >
                          <v-btn
                            v-if="permission.create_perm == 1"
                            @click="openDetailItemDialog"
                            style="font-weight: bold"
                            text
                            color="primary"
                            small
                          >
                            <v-icon left small> mdi-plus </v-icon>
                            Tambah data
                          </v-btn>
                          <div style="width: 310px">
                            <v-text-field
                              v-model="searchName"
                              append-icon="mdi-magnify"
                              label="Cari nama karyawan"
                              class="mx-4"
                              style="margin-left: 10px"
                            ></v-text-field>
                          </div>
                        </div>
                      </template>
                      <template v-slot:[`item.no`]="{ index }">
                        <div
                          style="
                            font-size: 14px;
                            padding: 0;
                            display: flex;
                            flex-direction: row;
                          "
                        >
                          {{ index + 1 + footer.offset * footer.limit }}
                        </div>
                      </template>
                      <template v-slot:[`item.period`]="{ item }">
                        <div
                          v-if="!item.isEdit"
                          :style="` font-size:12px; padding: 0; display: flex; flex-direction: row; justify-content: start; align-items:center;`"
                        >
                          {{ monthName(item.month) }} - {{ item.year }}
                        </div>
                        <div
                          v-else
                          :style="`width:100px; height:45px; font-size:12px; padding: 0; display: flex; justify-content: center; align-items:center;`"
                        >
                          <input
                            type="month"
                            style="
                              border: 1px solid black;
                              height: 30px;
                              width: 120px;
                              text-align: center;
                            "
                            v-model="item.period"
                          />
                        </div>
                      </template>
                      <!-- <template v-slot:[`item.year`]="{ item }">
                  <div
                    v-if="!item.isEdit"
                    :style="`width:40px; font-size:12px; padding: 0; display: flex; flex-direction: row; justify-content: start; align-items:center;`"
                  >
                    {{ item.year }}
                  </div>
                  <div
                    v-else
                    :style="`width:40px; height:45px; font-size:12px; padding: 0; display: flex; justify-content: center; align-items:center;`"
                  >
                    <input
                      type="text"
                      style="
                        border: 1px solid black;
                        height: 30px;
                        width: 70px;
                        text-align: center;
                      "
                      v-model="item.year"
                    />
                  </div>
                </template> -->
                      <template v-slot:[`item.employee.name`]="{ item }">
                        <div
                          v-if="item.act === undefined || item.act === 'update'"
                          :style="`width:180px; font-size:12px; padding: 0; display: flex; flex-direction: row; justify-content: start; align-items:center;`"
                        >
                          {{ item.employee.name }}
                        </div>

                        <div
                          v-else
                          :style="`height:40px; width:180px; font-size:12px; padding: 0; display: flex; justify-content: start; align-items:center;`"
                        >
                          <v-autocomplete
                            v-model="item.employee_id"
                            :items="dropdown.employee"
                            :search-input.sync="employeeSearch"
                            item-text="name"
                            item-value="id"
                            return-id
                            outlined
                            dense
                            hide-details
                            hide-no-data
                            style="margin: 0; height: 40px"
                            clearable
                          >
                            <template v-slot:item="data">
                              <template>
                                <v-list-item-avatar>
                                  <img :src="`${data.item.photo}`" />
                                </v-list-item-avatar>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{ data.item.name }}
                                  </v-list-item-title>
                                  <v-list-item-subtitle>
                                    {{ data.item.company_name }}-{{
                                      data.item.nik
                                    }}
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </template>
                            </template>
                          </v-autocomplete>
                        </div>
                      </template>
                      <template v-slot:[`item.amount`]="{ item }">
                        <div
                          v-if="!item.isEdit"
                          :style="`font-size:12px; width: 80px; padding: 0; display: flex; flex-direction: row; justify-content: start; align-items:center;`"
                        >
                          Rp{{ priceConvert(item.amount) }}
                        </div>

                        <div
                          v-else
                          :style="`height:45px; width: 80px; font-size:12px; padding: 0; display: flex; justify-content: start; align-items:center;`"
                        >
                          <input
                            type="number"
                            style="
                              border: 1px solid black;
                              height: 30px;
                              text-align: center;
                            "
                            v-model="item.amount"
                          />
                        </div>
                      </template>
                      <template v-slot:[`item.action`]="{ item }">
                        <div>
                          <v-tooltip left v-if="permission.update_perm == 1">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                @click.stop="editDetailItem(item)"
                                color="primary"
                                x-small
                                icon
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon small color="primary">
                                  mdi-pencil
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Edit</span>
                          </v-tooltip>
                          <v-tooltip right v-if="permission.delete_perm == 1">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                @click.stop="deleteItem(item)"
                                color="error"
                                x-small
                                icon
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon small color="error">
                                  mdi-delete
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Delete</span>
                          </v-tooltip>
                        </div>
                        <!-- <div v-else>
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click.stop="saveDetailItem(item, index)"
                          color="success"
                          x-small
                          icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon small color="success" class="">
                            mdi-content-save
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Simpan</span>
                    </v-tooltip>
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click.stop="cancelEditItem(item, index)"
                          color="error"
                          x-small
                          icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon small color="error" class="">
                            mdi-close
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Batal</span>
                    </v-tooltip>
                  </div> -->
                      </template>
                    </v-data-table>
                  </v-card>
                </v-col>
                <!-- <v-col cols="12" style="padding: 0 10px; margin-top: 50px">
            <v-btn
              elevation="0"
              color="primary"
              type="submit"
              class="white--text font-weight-bold me-5"
              style="font-size: 12px; width: 100%"
              :loading="loading"
            >
              Simpan
            </v-btn>
          </v-col> -->
              </v-row>
            </v-form>
          </v-card>

          <div
            v-if="loading"
            class="d-flex justify-center align-center"
            style="
              position: absolute;
              top: 0;
              z-index: 6;
              left: 0;
              width: 100%;
              height: 100%;
              background: rgba(255, 255, 255, 0.5);
            "
          >
            <v-progress-circular
              :size="100"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>

          <v-dialog v-model="detailItemDialog" persistent max-width="400px">
            <v-card v-if="formItem !== null">
              <v-card-title>
                <span class="text-h5">TAMBAH/EDIT DATA</span>
              </v-card-title>
              <v-form ref="entryFormItem" @submit.prevent="saveDetailItem">
                <v-card-text>
                  <v-container>
                    <v-row>
                      <!-- <v-col cols="12" class="d-flex flex-column">
                        <p
                          class="text-left"
                          style="
                            margin: 0;
                            font-size: 12px;
                            font-weight: bold;
                            color: rgba(0, 0, 0, 0.5);
                          "
                        >
                          Department
                        </p>
                        <div style="height: 40px">
                          <v-autocomplete
                            v-model="footer.department_id"
                            :items="dropdown.department"
                            dense
                            clearable
                            outlined
                            item-text="name"
                            item-value="id"
                            return-id
                          >
                          </v-autocomplete>
                        </div>
                      </v-col> -->
                      <v-col cols="12" class="d-flex flex-column">
                        <p
                          class="text-left"
                          style="
                            margin: 0;
                            font-size: 12px;
                            font-weight: bold;
                            color: rgba(0, 0, 0, 0.5);
                          "
                        >
                          Karyawan
                        </p>
                        <div style="height: 40px">
                          <v-autocomplete
                            v-model="formItem.employee_id"
                            :items="dropdown.employee"
                            :search-input.sync="employeeSearch"
                            :rules="rules.employee"
                            dense
                            clearable
                            outlined
                            item-text="name"
                            item-value="id"
                            return-id
                            label="ketik nama"
                          >
                            <template v-slot:item="data">
                              <template>
                                <v-list-item-avatar>
                                  <img :src="`${data.item.photo}`" />
                                </v-list-item-avatar>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{ data.item.name }}
                                  </v-list-item-title>
                                  <v-list-item-subtitle>
                                    {{ data.item.company_name }}-{{
                                      data.item.nik
                                    }}
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </template>
                            </template>
                          </v-autocomplete>
                        </div>
                      </v-col>

                      <v-col cols="12" class="d-flex flex-column">
                        <p
                          class="text-left"
                          style="
                            margin: 0;
                            font-size: 12px;
                            font-weight: bold;
                            color: rgba(0, 0, 0, 0.5);
                          "
                        >
                          Nominal
                        </p>
                        <v-text-field
                          dense
                          outlined
                          type="text"
                          v-model="formItem.amount"
                          style="height: 45px"
                          :rules="rules.amount"
                        />
                      </v-col>
                      <v-col cols="12" class="d-flex flex-column">
                        <p
                          class="text-left"
                          style="
                            margin: 0;
                            font-size: 12px;
                            font-weight: bold;
                            color: rgba(0, 0, 0, 0.5);
                          "
                        >
                          Periode
                        </p>
                        <v-text-field
                          dense
                          outlined
                          v-model="formItem.period"
                          type="month"
                          name="month"
                          step="1"
                          style="height: 45px"
                          :rules="rules.date"
                          disabled
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="closeDetailItemDialog"
                  >
                    Tutup
                  </v-btn>
                  <v-btn
                    v-if="permission.create_perm == 1"
                    color="blue darken-1"
                    text
                    type="submit"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
        </v-container>
      </div>
    </div>
    <v-card
      v-else
      class="mx-auto"
      width="100%"
      style="
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        position: fixed;
        top: 0;
        height: 100%;
        left: 0;
        right: 0;
        background: rgba(255, 255, 255);
      "
    >
      DATA NOT FOUND
    </v-card>
  </div>
  <div v-else>
    <v-card
      class="mx-auto"
      width="100%"
      style="
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        position: fixed;
        top: 0;
        height: 100%;
        left: 0;
        right: 0;
        background: rgba(255, 255, 255);
      "
    >
      <v-progress-circular :size="170" :width="10" color="blue" indeterminate
        >Loading...</v-progress-circular
      >
    </v-card>
  </div>
</template>
<script>
import axios from 'axios'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import buildType from '../../../../services/buildType'
const env = process.env.VUE_APP_BUILD_TYPE
export default {
  name: 'leavesubmission',
  components: {},
  data() {
    return {
      config: require('../../../../services/config.json')[env],
      hrsApi: buildType.apiURL('hrs'),
      eSanqua: buildType.apiURL('esanqua'),
      oAuthApi: buildType.apiURL('oauth'),
      build: process.env.VUE_APP_BUILD_TYPE,
      permission: null,
      appLevel: null,
      userLevel: null,
      isLoadingPage: true,
      loading: false,
      detail: null,
      form: null,
      formItem: null,
      duplicateItem: [],
      isEdit: false,
      detailItemDialog: false,
      wWidth: window.innerWidth,
      footer: {
        itemsPerPage: 10,
        options: {},
        page: 1,
        keyword: '',
        offset: 0,
        limit: 10,
        sortBy: 'id',
        sortType: 'ASC',
        department_id: null
      },
      detailHeaders: [
        {
          text: 'No',
          value: 'no',
          align: 'left',
          sortable: false
        },
        {
          text: 'Karyawan',
          value: 'employee.name',
          align: 'left',
          sortable: false
        },
        {
          text: 'Jabatan',
          value: 'employee.level.name',
          align: 'left',
          sortable: false
        },
        {
          text: 'Nominal',
          value: 'amount',
          align: 'left',
          sortable: false
        },
        {
          text: 'Periode',
          value: 'period',
          align: 'left',
          sortable: false
        },
        {
          text: 'Action',
          value: 'action',
          align: 'left',
          sortable: false
        }
      ],
      dropdown: {
        employee: [],
        department: [],
        payroll_template: []
      },
      searchName: '',
      employeeSearch: null,
      employeeSelect: null,
      rules: {
        employee: [],
        amount: [],
        date: []
      },
      unwatchItem: null
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from
    })
  },
  computed: {
    ...mapGetters(['getUserProfile', 'getLoginPayroll'])
  },
  async mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    this.appLevel = this.config.application.hr.userLevel
    // this.appLevel.administrator =
    //   this.config.application.all.userLevel.administrator
    if (this.getLoginPayroll) {
      this.verifyAccessToken()
    } else {
      setTimeout(() => {
        this.$router.push('/hr/payroll/additional')
      }, 100)
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  watch: {
    employeeSearch(val) {
      if (val !== null) {
        if (val !== '') {
          val && val !== this.employeeSelect && this.querySelections(val)
        } else {
          this.employeeSelect = null
          this.dropdown.employee = []
        }
      } else {
        this.employeeSelect = null
        this.dropdown.employee = []
      }
    }
  },
  methods: {
    ...mapActions(['menu_access', 'getMenuId']),
    ...mapMutations(['setLoginPayroll']),
    async verifyAccessToken() {
      this.loading = true
      await axios
        .post(
          `${this.oAuthApi}otp/access_token/verify`,
          {},
          {
            headers: {
              'x-access-token': this.getLoginPayroll
            }
          }
        )
        .then((res) => {
          console.log('verify access token>>>', res)
          if (res.data.status_code === '00') {
            this.getDataMenu()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'warning',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('warning', res.data.status_msg, false)
            }
            this.setLoginPayroll(null)
          }

          this.loading = false
        })
        .catch((err) => {
          console.log('error>>', err)
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.loading = false
        })
    },
    async getDataMenu() {
      this.isLoadingPage = true
      const split = this.$route.path.split('/')
      const routeModule = '/' + split[1] + '/' + split[2]
      console.log('Path>>>>>', routeModule)
      await this.getMenuId({
        keyword: '',
        path: routeModule
      })
        .then((result) => {
          if (result.data.status_code === '00') {
            const menuId = result.data.data[0].id
            this.userLevel = this.getUserProfile.level.find(
              ({ application }) =>
                application.id === this.config.application.hr.applicationId
              // ||
              // application.id === this.config.application.all.applicationId
            )
            console.log('userLevel>>>>>', this.userLevel)
            if (this.userLevel !== null) {
              this.checkMenuAccess([menuId, this.userLevel.id])
            } else {
              this.resetPermission(null)
            }
          } else {
            this.isLoadingPage = false
          }
        })
        .catch((err) => {
          console.log(err)
          this.isLoadingPage = false
        })
    },
    async checkMenuAccess(payload) {
      await this.menu_access(payload)
        .then((result) => {
          console.log('menuAccess>>>>', result)
          this.resetPermission(result)
        })
        .catch((err) => {
          console.log(err)
          this.resetPermission(null)
        })
    },
    resetPermission(val) {
      this.permission = null

      if (val !== null) {
        if (val.data !== null) {
          if (val.data.read_perm === 1) {
            this.permission = val.data
            this.initDataForLayout()
          }
        }
      }
      this.isLoadingPage = false
    },
    initDataForLayout() {
      this.isEdit = false
      // if (this.userLevel.is_support) {
      //   this.paramAPI.company_id = 0
      // } else {
      //   this.paramAPI.company_id = Number(
      //     this.getUserProfile.employee.company.plant_id
      //   )
      // }

      this.getDetail()
    },
    startWatcherItem() {
      this.unwatchItem = this.$watch('formItem.amount', (val) => {
        var removeChar = this.formItem.amount.replace(/[^0-9]/g, '') // This is to remove alphabets and special characters.

        var removeDot = removeChar.replace(/\./g, '') // This is to remove "DOT"
        this.formItem.amount = removeDot

        var formatedNumber = this.formItem.amount.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ','
        )
        this.formItem.amount = formatedNumber
      })
    },
    async querySelections(v) {
      this.loading = true

      let url = `${this.hrsApi}employee/dropdown?keyword=${v}`
      if (this.detail.company_id != null) {
        url = url + `&company_id=${this.detail.company_id}`
      }
      if (this.detail.template != undefined && this.detail.template != null) {
        if (
          this.detail.template.employee_level_id != undefined &&
          this.detail.template.employee_level_id != null
        ) {
          url = url + `&level_id=[${this.detail.template.employee_level_id}]`
        }
      }
      // if (this.detail.department_id !== null) {
      //   url = url + `&department_id=${this.detail.department_id}`
      // }
      await axios
        .get(url)
        .then((res) => {
          this.loading = false
          if (res.data.status_code === '00') {
            const resArr = []
            for (let i = 0; i < res.data.data.length; i++) {
              resArr.push({
                id: res.data.data[i].id,
                nik: res.data.data[i].nik,
                name: res.data.data[i].name,
                company_id: res.data.data[i].company_id,
                company_name: this.getCompanyAlias(res.data.data[i].company_id),
                photo: res.data.data[i].photo
              })
            }
            return (this.dropdown.employee = resArr)
          }
          return (this.dropdown.employee = [])
        })
        .catch((err) => {
          this.loading = false
          this.dropdown.employee = []
          return console.log(err)
        })
    },
    async dropdownDepartment(v) {
      await axios
        .get(
          `${this.hrsApi}master/universal/department/dropdown?filter=[{"company_id":${this.detail.company_id}}]`
        )
        .then((res) => {
          console.log('dropdownDepartment>>>>', res)
          let arrRes = []
          if (res.data.status_code === '00') {
            // const resArr = []
            // for (let i = 0; i < res.data.data.length; i++) {
            //   resArr.push({
            //     id: res.data.data[i].id,
            //     nik: res.data.data[i].nik,
            //     name: res.data.data[i].name,
            //     company_id: res.data.data[i].company_id,
            //     company_name: this.getCompanyAlias(res.data.data[i].company_id),
            //     photo: res.data.data[i].photo
            //   })
            // }
            arrRes = res.data.data
          }
          this.dropdown.department = arrRes
          return null
        })
        .catch((err) => {
          this.dropdown.department = []
          return console.log(err)
        })
    },
    async updatePage(p) {
      this.footer.page = p
      this.footer.offset = p - 1
    },
    async updateItemPerPage(p) {
      this.footer.limit = p
      this.footer.offset = 0
    },
    async getDetail() {
      this.loading = true
      const url = `${this.hrsApi}employee/payroll_additional/detail/${this.$route.params.id}`
      await axios
        .get(url, {
          headers: {
            'x-access-token': this.getLoginPayroll
          }
        })
        .then((res) => {
          console.log('Detaill >>>', res)
          if (res.data.status_code !== '-99') {
            const objData = {
              additional_detail: res.data.data.additional_detail,
              company_id: res.data.data.company_id,
              company_name: res.data.data.company_name,
              department_id: res.data.data.department_id,
              department_name: res.data.data.department_name,
              created_at: res.data.data.created_at,
              created_by_name: res.data.data.created_by_name,
              id: res.data.data.id,
              name: res.data.data.name,
              payroll_component: res.data.data.payroll_component,
              period_end_month: res.data.data.period_end_month,
              period_end_year: res.data.data.period_end_year,
              period_start_month: res.data.data.period_start_month,
              period_start_year: res.data.data.period_start_year,
              status: res.data.data.status,
              updated_at: res.data.data.updated_at,
              updated_by_name: res.data.data.updated_by_name,
              template: res.data.data.template,
              employee_level_id: res.data.data.employee_level_id
            }

            this.detail = objData
            this.dropdownDepartment()
            this.dropdownTemplate()
          } else {
            this.showMsgDialog('error', res.data.status_msg, false)
          }
          return (this.loading = false)
        })
        .catch((err) => {
          console.log(err)
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          return (this.loading = false)
        })
    },

    editHeader() {
      this.form = {
        period_start:
          this.detail.period_start_year +
          '-' +
          ((this.detail.period_start_month + '').length > 1
            ? this.detail.period_start_month
            : '0' + this.detail.period_start_month),
        period_end:
          this.detail.period_end_year +
          '-' +
          ((this.detail.period_end_month + '').length > 1
            ? this.detail.period_end_month
            : '0' + this.detail.period_end_month)
      }
      setTimeout(() => {
        this.isEdit = true
      }, 200)
    },
    cancelHeader() {
      this.form = null
      this.getDetail()
      setTimeout(() => {
        this.isEdit = false
      }, 100)
    },

    submitHeader() {
      const self = this
      // this.rules.nameRules = [v => !!v || 'Nama project diperlukan']
      setTimeout(function () {
        if (self.$refs.entryForm.validate()) {
          self.createNewForm()
        }
      })
    },
    createNewForm() {
      const form = {
        act: 'update',
        name: this.detail.name,
        company_id: this.detail.company_id,
        company_name: this.detail.company_name,
        period_start_month:
          this.form.period_start !== null
            ? Number(this.form.period_start.split('-')[1])
            : null,
        period_start_year:
          this.form.period_start !== null
            ? this.form.period_start.split('-')[0]
            : null,
        period_end_month:
          this.form.period_end !== null
            ? Number(this.form.period_end.split('-')[1])
            : null,
        period_end_year:
          this.form.period_end !== null
            ? this.form.period_end.split('-')[0]
            : null,
        payroll_component_id:
          this.detail.payroll_component !== null
            ? this.detail.payroll_component.id
            : null,
        template_id:
          this.detail.template !== null ? this.detail.template.id : null,
        employee_level_id:
          this.detail.template !== null
            ? this.detail.template.employee_level_id
            : null
      }
      if (form.payroll_component_id !== 13) {
        form.period_end_month = form.period_start_month
        form.period_end_year = form.period_start_year
      }

      if (form.act === 'update') {
        Object.assign(form, {
          id: this.detail.id
        })
      }
      // console.log(form)
      this.saveHeader(form)
    },

    async saveHeader(form) {
      this.loading = true
      await axios
        .post(`${this.hrsApi}employee/payroll_additional/save`, form)
        .then((res) => {
          this.loading = false
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            this.getDetail()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
          this.cancelHeader()
          return null
        })
        .catch((err) => {
          this.cancelHeader()
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.loading = false
          return null
        })
    },

    async editDetailItem(pItem) {
      if (this.permission.update_perm == 1) {
        const month = pItem.month + ''
        const year = pItem.year

        this.formItem = {
          act: 'update',
          id: pItem.id,
          period: year + '-' + (month.length > 1 ? month : '0' + month),
          amount: '0',
          employee_id: pItem.employee.id
        }
        this.startWatcherItem()

        await this.querySelections(pItem.employee.name)
        this.formItem.amount = pItem.amount + ''
        setTimeout(() => {
          this.detailItemDialog = true
        }, 100)
      }
    },
    async openDetailItemDialog() {
      const period =
        this.detail.period_start_year +
        '-' +
        ((this.detail.period_start_month + '').length > 1
          ? this.detail.period_start_month
          : '0' + this.detail.period_start_month)
      this.formItem = {
        act: 'add',
        period: period,
        amount: '0',
        employee_id: null
      }

      setTimeout(async () => {
        this.startWatcherItem()
        this.detailItemDialog = true
      }, 200)
    },
    closeDetailItemDialog() {
      this.$refs.entryFormItem.reset()
      this.unwatchItem()
      setTimeout(() => {
        this.formItem = null
        this.detailItemDialog = false
      }, 200)
    },
    saveDetailItem() {
      const self = this
      this.rules.employee = [(v) => !!v || 'Employee is required']
      this.rules.amount = [(v) => !!v || 'Amount is required']
      this.rules.date = [(v) => !!v || 'Period is required']

      setTimeout(function () {
        if (self.$refs.entryFormItem.validate()) {
          self.createNewFormItem()
        }
      })
    },
    createNewFormItem() {
      const form = {
        act: this.formItem.act,
        amount: this.formItem.amount,
        year: this.formItem.period.split('-')[0],
        month: Number(this.formItem.period.split('-')[1]),
        employee_id: this.formItem.employee_id
      }

      form.amount = Number(form.amount.replace(/,/g, ''))

      if (form.act === 'add') {
        Object.assign(form, {
          ref_id: this.detail.id
        })
      }
      if (form.act === 'update') {
        Object.assign(form, {
          id: this.formItem.id
        })
      }
      this.saveDetail(form)
    },

    async saveDetail(form) {
      this.loading = true
      await axios
        .post(`${this.hrsApi}employee/payroll_additional/save_detail`, form)
        .then((res) => {
          this.loading = false
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            this.closeDetailItemDialog()
            this.getDetail()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
          return null
        })
        .catch((err) => {
          this.cancelHeader()
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.loading = false
          return null
        })
    },
    async deleteItem(pItem) {
      this.loading = true
      this.showMsgDialog(
        'question',
        'Are you sure, This data will be deleted permanently',
        true
      ).then(async (res) => {
        if (res.isConfirmed) {
          await axios
            .delete(
              `${this.hrsApi}employee/payroll_additional/delete_detail/${pItem.id}`
            )
            .then(async (res) => {
              if (res.data.status_code !== '-99') {
                this.showMsgDialog('success', res.data.status_msg, false)
                this.getDetail()
              } else {
                this.showMsgDialog('warning', res.data.status_msg, false)
              }
            })
            .catch((err) => {
              console.log(err)
              this.showMsgDialog(
                'error',
                err
                  ? 'Something went wrong, Please contact an admin!'
                  : 'Something went wrong, Please contact an admin!',
                false
              )
            })
        }
        this.loading = false
      })
    },
    close() {
      if (this.prevRoute !== undefined) {
        if (this.prevRoute.path !== '/hr/payroll/additional/submission') {
          this.$router.push(this.prevRoute.path)
          return ''
        }
      }
      this.$router.push('/hr/payroll/additional')
    },
    async dropdownTemplate() {
      const url = `${this.hrsApi}master/universal/payrolltemplate/dropdown?source=payroll&company_id=${this.detail.company_id}`
      // if (this.detail.company_id !== null) {
      //   url = url + `&company_id=${this.detail.company_id}`
      // }
      await axios
        .get(url)
        .then((res) => {
          if (res.data.status_code === '00') {
            return (this.dropdown.payroll_template = res.data.data)
          }
          return (this.dropdown.payroll_template = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.payroll_template = [])
        })
    },
    convertDate(raw) {
      if (raw !== null) {
        const date = new Date(raw)
        const local = date.toLocaleDateString()
        return local
      }
    },
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    onResize() {
      this.wWidth = window.innerWidth
    },
    priceConvert(xVal) {
      const num = 0
      if (xVal !== undefined) {
        if (xVal !== null) {
          let round = xVal
          if (round.toString().includes('.')) {
            round = round.toFixed(2)
          }
          const val = round + ''
          let result = ''

          if (val.includes('.')) {
            const splitVal = val.split('.')
            const main = splitVal[0]
            const decimal = splitVal[1]
            result =
              main.toString().replace(/\B(?<!\\d*)(?=(\d{3})+(?!\d))/g, ',') +
              `.${decimal}`
          } else {
            result = val
              .toString()
              .replace(/\B(?<!\\d*)(?=(\d{3})+(?!\d))/g, ',')
          }
          return result
        }
      }
      return num
    },
    monthName(val) {
      switch (val) {
        case 1:
          return 'JANUARI'
        case 2:
          return 'FEBRUARI'
        case 3:
          return 'MARET'
        case 4:
          return 'APRIL'
        case 5:
          return 'MEI'
        case 6:
          return 'JUNI'
        case 7:
          return 'JULI'
        case 8:
          return 'AGUSTUS'
        case 9:
          return 'SEPTEMBER'
        case 10:
          return 'OKTOBER'
        case 11:
          return 'NOVEMBER'
        case 12:
          return 'DESEMBER'
      }
    },

    getCompanyAlias(id) {
      switch (id) {
        case 1:
          return 'TMP'
        case 2:
          return 'IMP'
        case 3:
          return 'GIT'
        case 4:
          return 'KAJ'
        case 5:
          return 'WIM'
        case 6:
          return 'SMI'
        case 7:
          return 'THP'
        case 8:
          return 'GAI'
        case 9:
          return 'GPP'
        case 10:
          return 'TMP2'
        case 11:
          return 'INO'
        case 12:
          return 'BPS'
        case 13:
          return 'BPC'
        case 14:
          return 'WIMS'
        case 15:
          return 'KAJ2'
      }
    }
  }
}
</script>
